<template>
  <div
    class="fixed top-0 left-0 z-50 flex items-center block w-full h-full bg-black opacity-75"
  >
    <div class="w-full text-center text-blue-600 opacity-75">
      <i class="fas fa-sync-alt fa-spin fa-5x"></i>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>